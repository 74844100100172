/**
 ⭐ 重定向组件
 --------------------
 * 基于用户浏览器语言重定向页面（非中文时进行跳转）
 * @author shuakami
 * @copyright ByteFreeze&TofuUI
 */

"use client";

import { useEffect } from "react";
import { useRouter, usePathname } from "next/navigation";

/**
 * 获取用户语言
 * @returns {string} 用户语言代码
 */
const getUserLanguage = (): string => {
  if (typeof navigator !== 'undefined') {
    return navigator.language || 'en';
  }
  return 'zh';
};

/**
 * LanguageRedirect 组件根据用户浏览器的语言设置来重定向用户到相应的语言页面。
 * 当用户语言为中文时，不进行跳转。
 */
const LanguageRedirect: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const userLang = getUserLanguage(); // 获取用户语言
    const isEnglish = userLang.startsWith('en');
    const isChinese = userLang.startsWith('zh');

    // 确保路径已定义且非中文时进行重定向
    if (pathname && !isChinese) {
      // 特殊-当前页面为 /docs 且需要加 .en
      if (pathname === '/docs' && isEnglish) {
        router.replace(`/docs/index.en`);
      } else if (isEnglish && !pathname.endsWith('.en')) {
        router.replace(`${pathname}.en`);
      }
    }
  }, [pathname, router]);

  return null;
};

export default LanguageRedirect;
